import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { GetAppBarTitle } from './Customize'
import LogoutIcon from '@mui/icons-material/Logout'
import { PagePropsType } from './PagePropsType'
import { Download } from './Download'
import { View } from './View'
import { ContentsList } from './ContentsList'

export type CMSTopPropsType = {
  signOut: any,
  user: any
};

const API_BASE = "https://g9ul7zshq4.execute-api.ap-northeast-1.amazonaws.com/v1/";

export const CMSTop = (props: CMSTopPropsType) => {
  const [token, setToken] = useState<string>('');

  // UI初期化時処理.
  useEffect(()=>{
    // Tokenの取得.
    Auth.currentSession().then((s) => {
      setToken(s.getIdToken().getJwtToken());
    });
    console.log(props.user)

    const timeout = 600000; /* 600 seconds */
    const logoutCheck: NodeJS.Timeout = setTimeout(()=>{
      props.signOut();
    }, timeout);
    return () => {
      clearTimeout(logoutCheck);
    }
  }, []);

  const Route = (props:PagePropsType) => {
    const hash = window.location.hash;
    switch(hash) {
      case "#ContentsList": return <ContentsList {...props}/>;
      case "#Download": return <Download {...props}/>;
      default: return <ContentsList {...props}/>
    }
  }

  return (
    <>
      <AppBar position="static" color="secondary">
        <Toolbar>
          {GetAppBarTitle()}
          <div style={{ marginLeft: 'auto' }}>
            <IconButton color="inherit" onClick={() => {
              props.signOut()
            }}>
              <LogoutIcon/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {Route({api_base: API_BASE, token: token, user: props.user})}
    </>
  );
}
