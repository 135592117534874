import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {QRCodeSVG} from "qrcode.react";
import {PagePropsType} from "./PagePropsType";
import InfiniteScroll from "react-infinite-scroller";
import {GetContentsListDescription} from "./Customize";

type URLType = {
    title: string,
    uuid: string,
    thumbnail: string,
    order: number,
    timestamp: number,
    hide: boolean
};

type Response =  {
    group: string,
    total: number,
    item_num: number,
    item_offset: number,
    urllist: URLType[]
};

export const Content = (props = {title:"XXXX", uuid:"",thumbnail:"",group:"", clickable:false}) =>{
    const url = window.location.protocol + "//" + window.location.host
      + "/index.html?group=" + props.group + "&page=" + props.uuid
      + "#Download";
    const make_title = (hour:string) =>{
        let h:number = Number.parseInt(hour);
        h = h % 24;
        return ('00' + h).slice(-2);
    }

    let qr = (<QRCodeSVG value={url}
                                size={96}
                                level={'L'}/>);
    if (props.clickable) qr = (<a href={url}>{qr}</a>);
    return (
        <Box>
            <Box>
                <Typography variant="h3" sx={{m: "0.2rem"}} >
                    {props.title}
                </Typography>
            </Box>
            <Box sx={{display:'flex', justifyItems:'center'}}>
                <a href={url}>

                <Box component="img"
                     src={props.thumbnail}
                     sx={{
                         width: '100%',
                         borderRadius: 2
                     }}

                />
                </a>
            </Box>
        </Box>
    );

}

export const ContentsList = (props:PagePropsType) => {
    const url = props.api_base + "url_list";
    type ImageList = Record<string,Blob>;
    const [group, setGroup] = useState<string>("");
    const [images, setImages] = useState<ImageList>({});
    const [urllist, setURLList] = useState<URLType[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const token = props.token?props.token:'';

    const getUrlList = () => {
        return urllist;
    }

    const loadList = async(page: number) => {
        try {
            if (!token) return;
            const load_units = 3;
            const offset = urllist.length;
            const res = await fetch(
              url + "?offset=" + offset + "&num=" + load_units, {
                  method: 'GET',
                  headers: new Headers({
                      'Accept-Encoding': 'gzip',
                      'Content-Type': 'application/json',
                      'Authorization': token
                  }),
                  cache: "no-store"
              });
            if (!res.ok) return;
            const js = await res.json();

            const duplicated = js.urllist.filter((u: URLType) => {
                return urllist.find((check) => {return check.uuid == u.uuid});
            });
            if (duplicated.length > 0) {
                setURLList([]);
                setHasMore(true);
                return;
            }
            // サムネイルの読み込み.
            let thumbnail_tasks: Promise<{ key: string, blob: Blob }>[] = [];
            const urls = js.urllist.map((u: URLType) => {
                if (u.uuid in images) {
                } else {
                    thumbnail_tasks.push(
                      new Promise((res, rej) => {
                          fetch(u.thumbnail, {
                              method: 'GET',
                              mode: 'cors'
                          }).then((res) => {
                              if (!res.ok) {
                                  rej("failed to fetch url")
                              } else {
                                  return res.blob();
                              }
                          }).then((blob) => {
                              if (blob) {
                                  res({ key: u.uuid, blob: blob });
                              } else {
                                  rej("No blob found.");
                              }
                          }).catch((err) => {
                              console.log("Failed to get url");
                          })
                      }));
                }
                return {
                    title: u.title,
                    uuid: u.uuid,
                    hide: u.hide
                }
            });
            let thumbnails = await Promise.all(thumbnail_tasks);

            if (thumbnails.length > 0) {
                setImages((prevState) => {
                    for (let t of thumbnails) {
                        prevState[t.key] = t.blob;
                    }
                    return prevState;
                });
            }
            setGroup(js.group);
            const updated = [...urllist, ...urls];
            setHasMore(updated.length < js.total);
            setURLList(updated);
        } catch (err){
            console.warn(err);
        }
    }

    const checkNewContent = async()=>{
        // download url-list and unzip data.
        try {
            const load_units = 3;
            const res = await fetch(url + "?offset=0" + "&num=" + load_units, {
                method: 'GET',
                headers: new Headers({
                    'Accept-Encoding': 'gzip',
                    'Content-Type': 'application/json',
                    'Authorization': token
                }),
                cache: "no-store"
            });
            console.log("fetch returns ");
            if (!res.ok) return;
            const js = await res.json();
            if (js.total < urllist.length) {
                setURLList([]);
                setHasMore(true);
            } else if (js.total > urllist.length) {
                setHasMore(true);
            }
        } catch (err){
            console.warn(err);
        }
    };

    useEffect(()=>{
        // download url-list and unzip data.
        const timeoutId: NodeJS.Timeout = setInterval(checkNewContent, 5000);
        return () =>{
            clearInterval(timeoutId);
        }
    }, [urllist]);
    const filtered = urllist.filter((u)=>{return !u.hide});

    const body = (<InfiniteScroll pageStart={-1}
                                  loadMore={loadList}
                                  hasMore={hasMore}
                                  useWindow={true}>
        <Grid container spacing={2}>
            {filtered.map((u)=> {
                if (!u) return;
                let imgurl = "";
                if (u.uuid in images) {
                    imgurl = (window.URL || window.webkitURL).createObjectURL(images[u.uuid]);
                }
                let clickable = true;
                return (
                    <Grid item xs={12} sm={4}>
                        <Content
                            title={u.title}
                            uuid={u.uuid}
                            group={group}
                            thumbnail={imgurl}
                            clickable={clickable}
                        />
                    </Grid>
                );
            })
            }
        </Grid>
    </InfiniteScroll>);

    return (
        <Container maxWidth={false} >
            {GetContentsListDescription()}
            <Box sx={{height:10}}/>
            <div style={{height:"100%",overflow:"auto"}}>
            {body}
            </div>
            <Box sx={{height:10}}/>
        </Container>);
}
