import { GetLoginLogo, GetLoginMsg } from './Customize'
import { Button, Container, Grid, Typography } from '@mui/material'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import React from 'react'
import { CMSTop } from './CMSTop'

/**
 * ログイン時のUIカスタマイズ要素.
 * @param show_reset
 * @constructor
 */
const AuthenticateComponent = (show_reset=false) => {
  const msg = GetLoginMsg();
  return {
    SignIn: {
      Header(){
        return ( <Grid container  sx={{ p: 2 }} >
            {GetLoginLogo()}
          </Grid>
        )
      },
      Footer(){
        const {toResetPassword} = useAuthenticator();
        return (
          <Grid container sx={{ p: 2 }}>
            {show_reset?(
              <Container>
                <Button onClick={toResetPassword}>
                  パスワードをリセットする.
                </Button>
              </Container>
            ):null}
            <Container>
              <Typography variant='h6' color='error'>
                {msg}
              </Typography>
            </Container>
          </Grid>);
      }
    }
  }

}

export type LoginControlPropsType = {
}

export const LoginControl = (props:LoginControlPropsType)=> {
  const query = new URLSearchParams(document.location.search);
  const show_password_reset = query.get("show_password_reset");
  const flag:boolean = (show_password_reset != null &&
    (show_password_reset === "true" || show_password_reset === "on"));



  return (<Authenticator components={AuthenticateComponent(flag)} hideSignUp={true}>
      {
        ({signOut, user}) =>(
          <CMSTop
            signOut={signOut}
            user={user}
          />)
      }
    </Authenticator>
)
}
