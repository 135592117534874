import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import {Amplify, I18n} from "aws-amplify";
import {translations} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { CssBaseline,ThemeProvider} from "@mui/material";
import {createTheme} from '@mui/material/styles';
import { GetSecondaryThemeColor} from "./Customize";
import { LoginControl } from './LoginControl'


let rootElement = document.getElementById('root');
if (!rootElement) throw new Error('...');
const root = ReactDOM.createRoot(rootElement)

// stw-golf
const AuthConf = {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_NlXFH4PoY",
    userPoolWebClientId: "6ur2dnnnpudg3i9lse33mf8qqn",
    authenticationFlowType: 'USER_SRP_AUTH'
};

Amplify.configure({Auth: AuthConf});
I18n.putVocabularies(translations);
I18n.setLanguage('ja');


type MainPropsType = {
    hash: string,
    query: URLSearchParams
};

export function Main(props:MainPropsType) {
    const theme = createTheme({
        palette: {
            secondary:{
                main: GetSecondaryThemeColor()
            }
        },
        typography: {
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 700,

            h1: { fontSize: 60 },
            h2: { fontSize: 48 },
            h3: { fontSize: 42 },
            h4: { fontSize: 36 },
            h5: { fontSize: 28 },
            h6: { fontSize: 18 },
            subtitle1: { fontSize: 18 },
            body1: { fontSize: 16 },
            button: { textTransform: 'none' },
        }
    });
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <LoginControl/>
        </ThemeProvider>
    );
}
const query = new URLSearchParams(document.location.search);

root.render(
    <Main hash={window.location.hash} query={query}/>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
