import NCCLogo from "./ncc-text.png";
import {Box, Container, Grid, Typography} from "@mui/material";
import LogoStreamWays from "./streamways-wtxt.svg";
import Logo from "./streamways-mono.svg";
import LogoText from "./streamways-mono-txt.svg";
import React from "react";
import { blue, grey } from '@mui/material/colors'


// --> For Default StreamWays

export const GetLoginMsg = ()=>{
  return "";
}

export const GetSecondaryThemeColor = ()=>{
  return grey['500'];
}

export const GetDownloadDescription = () => {
  return "";
}
export const GetLoginLogo = ()=>{
    return (
        <Container sx={{display:'flex', placeSelf:'center', placeContent:'center'}}>
            <Box component="img"
            src={LogoStreamWays}
            sx={{height:100, my:4}}
            />
        </Container>);
 }
export const GetAppBarTitle = ()=>{
    return (<>
            <Box
                component="img"
                src={Logo}
                sx={{height: 40}}
            />
    <Box component="img"
     src={LogoText}
     sx={{height: 30,
         marginTop:1
     }}
    />
    </>)
}

export const GetContentsListDescription = () => {
  return "";
}

/*

// --> For Nihon Country Club.
export const GetLoginLogo = ()=>{
    return null;
}

export const GetLoginMsg = ()=>{
    return "月例参加者に周知されたユーザ名、パスワードを入力し、サインインをタッチしてください";
}

export const GetSecondaryThemeColor = ()=>{
    return "#8E3A38";
}

export const GetAppBarTitle = () => {
    return (<>
        <Box component="img"
            src={NCCLogo}
            sx={{height: 40,
            marginTop:2
        }}/>
        </>);
}

export const GetContentsListDescription = () =>{
    const msg1 = "画面よりご自身を検索し、下部のQRコードをスマホで読み取ることで、スイング動画をダウンロードできます。";
    const msg2 = "（時刻は撮影時刻を表しています）";
    return (
        <Container>
            <Box sx={{height:20}}/>
            <Typography variant={"h5"}>
                <div>{msg1}</div>
                <div>{msg2}</div>
            </Typography>
        </Container>
    );
}

*/
