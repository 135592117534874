import {useEffect, useState} from "react";
import {Alert, Box, Button, Container, Grid, Typography} from "@mui/material";
import React from 'react';
import {View} from "./View";
import { PagePropsType } from './PagePropsType'
import { GetDownloadDescription } from './Customize'

type PageInfo = {
  movies: string[],
  thumbnails: string[],
  start: string,
  expires: number,
  expire2: string,
  title: string|null
};

export const MovieList = (props:{pageinfo:PageInfo, onUpdatePage:(no:number) =>void}) =>{
    let images = [];
    const get_file_name = (url:string) => {
      const u = new URL(url);
      if (!u || !u.pathname) return "";
      const dirs = u.pathname.split("/");
      const exts = dirs[dirs.length-1].split(".");
      return exts[0];
    }

    for (let i = 0; i < props.pageinfo.movies.length; ++i){
        const img = props.pageinfo.thumbnails[i];
        //console.log(params.movies[i]);
        //const movie = encodeURIComponent(params.movies[i]);
        //const url = window.location.protocol +"//" + window.location.host + window.location.pathname + "?movie=" + movie + "#view";
        images.push(
            <Grid item xs={6}>
                <div className="my-3"/>
              <Box>
                {get_file_name(props.pageinfo.movies[i])}
              </Box>
                <Button
                   onClick={()=>{
                       props.onUpdatePage(i);
                   }
                   }
                >
                <Box component="img"
                     src={img}
                     sx={{
                         display: 'flex',
                         width:'100%',
                         borderRadius: 2
                     }}
                />
                </Button>
            </Grid>
        );
    }

    return (
        <>
            <Container >
                <Box sx={{height:10}}/>
                {GetDownloadDescription()}
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {props.pageinfo.title?props.pageinfo.title:props.pageinfo.start}
                    </Typography>
                </Grid>
                <Grid container spacing={2}>{images}</Grid>
                <Box sx={{m:2}}/>
            </Container>
        </>
    );
}


export const Download = (props:PagePropsType) =>{
    const api = props.api_base + "download_page";
    const [params, setParams] = useState<PageInfo|null>(null)
    const [pageno, setPageNo] = useState<number>(-1);
    const [error, setError] = useState<string|null>(null);
    const query = new URLSearchParams(document.location.search);
    const token = props.token?props.token:'';
    let group: string|null = query.get('group');
    let url: string|null = query.get('page');
    if (url != null) {
        url = api + "?group="+group + "&page=" + url;
    }

    useEffect(()=>{
        if (url && token && token.length > 0) {
            fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept-Encoding': 'gzip',
                    'Content-Type': 'application/json',
                    'Authorization': token
                }),
                cache: "no-store"
            })
            .then(res => {
              if (res.ok) return res.json();
            })
            .then( data => {
              console.log(data);
              setParams(data);
            })
            .catch(err => {
              setError("存在しないページです")
            });
        }
    }, [token, url]);

    let body = null;

    if (error) {
        body = (
            <Alert key='error' severity="error">
                {error}
            </Alert>
        )
    } else if (pageno < 0){
        if( params && params.thumbnails.length > 0 ) {
            body = (<MovieList pageinfo={params}
                               onUpdatePage={(no:number)=>{
                                   window.location.hash="#Downloade";
                                   setPageNo(no);
                               }}
            />);
        } else {
            body = null;
        }
    } else {
        if (params && params.movies && pageno < params.movies.length) {
            body = (<View url={params.movies[pageno]}
                          onBackToList={()=>{
                              window.location.hash="#Downloade";
                              setPageNo(-1);}
                          }
            />);
        } else {
            body = null;
        }

    }

    return (
        <>
            {body}
        </>

    );
}
