import React, { createRef, useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import { Download, PlayArrow, Stop } from '@mui/icons-material'

export const View = (props = { url: '', onBackToList: () => {} }) => {
  const [data, setData] = useState<Blob | null>(null)
  const [url, setURL] = useState<string>('')
  const [playButton, updatePlayButton] = useState<any>(
    <Button variant="outlined" endIcon={<PlayArrow/>} size="large" disabled>
      再生開始
    </Button>)
  let player = useRef<HTMLVideoElement>()

  const GetPlayButton = () => {
    return (<Button variant="outlined" endIcon={<PlayArrow/>} size="large"
                    onClick={() => {
                      onPlay()
                    }}>
      再生開始
    </Button>)
  }

  const get_file_name = (url: string) => {
    const u = new URL(url)
    if (!u || !u.pathname) return ''
    const dirs = u.pathname.split('/')
    const exts = dirs[dirs.length - 1].split('.')
    return exts[0]
  }

  const GetPauseButton = () => {
    return (<Button variant="outlined" endIcon={<Stop/>} size="large"
                    onClick={() => {
                      onPause()
                    }}>
      再生停止
    </Button>)
  }

  const onPlay = () => {
    if (player.current) {
      player.current.play()
    }
  }

  const onPause = () => {
    if (player.current) {
      player.current.pause()
    }
  }

  useEffect(() => {
    // Blobを使わないバージョン
    if (true) {
      setURL(props.url)
      updatePlayButton(GetPlayButton())
    } else {
      fetch(props.url).then((r) => {
        return r.blob()
      }).then((b) => {
        console.log('data downloaded.' + b.type)
        //b.type="video/mp4";
        const b2 = b.slice(0, b.size, 'video/mp4')
        setData(b2)
        setURL(window.URL.createObjectURL(b2))
        updatePlayButton(GetPlayButton())
      })
    }
  }, [])
  useEffect(() => {
    window.addEventListener('popstate', overridePopstate, false)
    return () => window.removeEventListener('popstate', overridePopstate,
      false)
  }, [])
  const overridePopstate = () => {
    props.onBackToList()
  }

  return (
    <Grid container>
      <Grid item xs={4} sx={{display:'flex'}}>
      <Box sx={{alignSelf:'center'}}>
        {get_file_name(props.url)}
      </Box>
      </Grid>
      <Grid item xs={8}>
      <Box sx={{ display: 'flex', justifyContent: 'right', m: 1 }}>
        <Button variant="outlined" endIcon={<Download/>} size="large"
                href={url}
          //                        disabled={data===null}
                download
        >
          保存
        </Button>
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box key="video"
           id="video"
           component="video"
           src={url}
           width="100%"
           ref={player}
           controls
           onPlay={() => {
             updatePlayButton(GetPauseButton())
           }}
           onPause={() => {
             updatePlayButton(GetPlayButton())
           }}
           onEnded={() => {
             updatePlayButton(GetPlayButton())
           }}
      />
      </Grid>
      <Grid item xs={12}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {playButton}
      </Box>
      </Grid>
    </Grid>
  )
}
